<template>
  <div class="link-container">
    <a href="https://www.instagram.com">
      <img src="../assets/instagram_color.svg" alt="instagram link" />
    </a>
  </div>
</template>
<script>
export default {};
</script>
<style lang="less">
.link-container {
  max-width: 80%;
  margin-left: 15%;
  margin-top: 15%;
  margin-bottom: 15%;
  a {
    img {
      max-width: 70%;
    }
  }
}
</style>
