<template>
  <div class="site-header">
    <h1>Bricht Heids</h1>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less">
.site-header {
  background-color: #3b4f66;
  color: #f4f4f4;
  height: 100%;
  h1 {
    height: 1rem;
  }
}
</style>
