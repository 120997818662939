<template>
  <div id="app">
    <site-header class="header" />
    <router-view class="main" />
    <side-bar />
    <div class="footer" />
  </div>
</template>
<script>
import Header from "./components/Header.vue";
import SideBar from "./components/SideBar.vue";
export default {
  components: {
    "site-header": Header,
    "side-bar": SideBar
  }
};
</script>
<style lang="less">
body {
  margin: 0;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: grid;
  align-items: center;
  font-size: 100%;
  height: 100vh;
  width: 100vw;
  grid-auto-flow: column;
  grid-template-columns: [left] 20% [mid-left] auto [mid-right] 20% [right];
  grid-template-rows: [start] 25% [main] 1fr [footer] 5% [end];
  .header {
    grid-column: left / span right;
    grid-row: 1;
    margin-bottom: 2%;
  }
  .main {
    padding-top: 2%;
    grid-column: mid-left;
    grid-row: main;
  }
  .sidebar {
    grid-column: left;
    grid-row: main / footer;
  }
  .footer {
    min-height: 5rem;
    grid-column: left / span right;
    grid-row: footer / end;
    background-color: #3b4f66;
  }
}
</style>
