<template>
  <div class="subtitle">{{ subtitle }}</div>
</template>
<script>
export default {
  data() {
    return {
      subtitle:
        "Software Engineering & Architecture Consulting, BBQ Teaching, and Catering"
    };
  }
};
</script>
<style lang="less">
.subtitle {
  grid-area: subtitle;
  font-size: 1.25rem;
}
</style>
