<template>
  <div class="contact-form">
    <form class="vue-form" @submit.prevent="submit">
      <fieldset>
        <legend>Contact Us</legend>
        <div>
          <label class="label" for="name">Name</label>
          <input type="text" name="name" id="name" required="" v-model="name" />
        </div>
        <div>
          <label class="label" for="email">Email</label>
          <input
            type="email"
            name="email"
            id="email"
            required=""
            :class="{ email, error: !email.valid }"
            v-model="email.value"
          />
        </div>
        <div>
          <label class="label" for="textarea">Message</label>
          <textarea
            class="message"
            name="textarea"
            id="textarea"
            required=""
            v-model="message.text"
            :maxlength="message.maxlength"
          ></textarea>
          <span class="counter"
            >{{ message.text.length }} / {{ message.maxlength }}</span
          >
        </div>
        <div class="submit-control">
          <input type="submit" value="Send" />
        </div>
      </fieldset>
    </form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      name: "",
      email: {
        value: "",
        valid: true
      },
      message: {
        text: "",
        maxlength: 255
      },
      submitted: false
    };
  },
  methods: {
    // submit form handler
    submit: function() {
      this.submitted = true;
    },
    // validate by type and value
    validate: function(type, value) {
      if (type === "email") {
        this.email.valid = this.isEmail(value) ? true : false;
      }
    },
    // check for valid email adress
    isEmail: function(value) {
      const emailRegExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
      return emailRegExp.test(value);
    }
  },
  watch: {
    // watching nested property
    "email.value": function(value) {
      this.validate("email", value);
    }
  }
};
</script>
<style lang="less">
.contact-form {
  width: 75%;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 10%;
  .vue-form {
    font-size: 0.75rem;
    padding: 0.15rem 0.3rem;
    border-radius: 0.1rem;
    margin: 0.2rem;
    background-color: #fff;
    box-shadow: 0 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.3);
    fieldset {
      margin: 0.5rem 0 0 0;
      border-color: #2c3e50;
    }
    legend {
      padding-bottom: 0.25rem;
    }
    div {
      position: relative;
      margin: 0.2rem 0;
      align-content: center;
    }
    .label {
      color: #94aab0;
      margin-bottom: 0.1rem;
      display: block;
    }
    input,
    textarea,
    label {
      color: #2c3e50;
    }
    input[type="text"],
    input[type="email"],
    textarea,
    legend {
      display: block;
    }
    input[type="text"],
    input[type="email"],
    textarea {
      width: 95%;
      padding: 0.5rem;
      border: 0.1rem solid #cfd9db;
      background-color: #ffffff;
      border-radius: 0.25em;
      font-size: 0.75rem;
      box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.08);
    }
    input[type="text"]:focus,
    input[type="email"]:focus,
    textarea:focus {
      outline: none;
      border-color: #afafaf;
      box-shadow: 0 0 0.05rem rgba(44, 151, 222, 0.2);
    }
    textarea {
      min-height: 4rem;
      resize: vertical;
      overflow: auto;
    }
    input[type="submit"] {
      border: none;
      background: #2c3e50;
      border-radius: 0.5em;
      padding: 0.2rem 0.3rem;
      color: #ffffff;
      font-weight: normal;
      float: right;
      right: 0.5rem;
      cursor: pointer;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    .no-touch input[type="submit"]:hover {
      background: #42a2e1;
    }
    input[type="submit"]:focus {
      outline: none;
      background: #2b3e51;
    }
    input[type="submit"]:active {
      transform: scale(0.9);
    }
    .counter {
      background-color: #ecf0f1;
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
      font-size: 0.1rem;
      padding: 0.04rem;
    }
    .submit-control {
      margin-top: 2%;
      margin-right: 2%;
    }
  }
  @-webkit-keyframes cd-bounce {
    0%,
    100% {
      -webkit-transform: scale(1);
    }
    50% {
      -webkit-transform: scale(0.8);
    }
  }
  @-moz-keyframes cd-bounce {
    0%,
    100% {
      -moz-transform: scale(1);
    }
    50% {
      -moz-transform: scale(0.8);
    }
  }
  @keyframes cd-bounce {
    0%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.8);
    }
  }
}
</style>
