<template>
  <div class="sidebar">
    <instagram-link class="instagram-link" />
    <twitter-link class="twitter-link" />
  </div>
</template>

<script>
import Instagram from "./InstagramLink.vue";
import Twitter from "./TwitterLink.vue";
export default {
  components: {
    "twitter-link": Twitter,
    "instagram-link": Instagram
  }
};
</script>
<style lang="less">
.sidebar {
  width: 60%;
  height: 100%;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: [left] auto [right];
  grid-template-rows: [first-social] auto [second-social] auto [rest] 1fr;
  .instagram-link {
    grid-column: left;
    grid-row: first-social;
  }
  .twitter-link {
    grid-column: left;
    grid-row: second-social;
  }
}
</style>
