<template>
  <div class="home">
    <sub-title class="subtitle" />
    <div class="bio">
      <p></p>
    </div>
    <contact-me class="contact-form" />
  </div>
</template>

<script>
import Contact from "../components/ContactForm.vue";
import SubTitle from "../components/SubTitle.vue";
export default {
  components: {
    "contact-me": Contact,
    "sub-title": SubTitle
  },
  name: "home"
};
</script>
<style lang="less">
.home {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-columns: [left] auto [right];
  grid-template-rows: [subtitle] auto [content] 1fr [contact] auto;
  grid-gap: 1rem;
  .subtitle {
    grid-row: subtitle;
    grid-column: left;
  }
  .bio {
    width: 80%;
    grid-row: content;
    grid-column: left;
    text-align: left;
    font-size: 1rem;
    padding: 1rem;
    margin-left: auto;
    margin-right: auto;
  }
  .contact-form {
    grid-row: contact;
    grid-column: left;
    font-size: 1rem;
    padding: 1rem;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
